import { default as _91_46_46_46pageSlug_93Jdg49vUoLQMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activater95u5356pHMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/activate.vue?macro=true";
import { default as indexZ7Fk7qVgMiMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_937hH5Zx06jaMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as new7gDixLYGmMMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlist6AKaSMrM29Meta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/watchlist.vue?macro=true";
import { default as indexBsj3WiCJqhMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infosJQ3FocPLjLMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as program4sfsAALpdrMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as index0rae1Zfc7AMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_93PXtp0EY5tjMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_93bwTVLw1qSXMeta } from "/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_93bwTVLw1qSXMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "/en/movie/:movieSlug/infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___en",
    path: "/en/movie/:movieSlug/program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93PXtp0EY5tjMeta?.name,
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91movieSlug_93bwTVLw1qSXMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93PXtp0EY5tjMeta?.name,
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91movieSlug_93bwTVLw1qSXMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93PXtp0EY5tjMeta?.name,
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-10-09_07-49-47_74179ef/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]